import { graphql } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import parse from "html-react-parser";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import Navigation from "../components/navigation";

const BlogPostTemplate = ({ data: { post, site, otherPosts, blogImg } }) => {
	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Blog",
				item: {
					url: `${siteUrl}/blog`,
					id: `${siteUrl}/blog`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${post.title}`,
				item: {
					url: `${siteUrl}/blog/${post.slug}`,
					id: `${siteUrl}/blog/${post.slug}`,
				},
			},
		],
	};
	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={post?.seo?.title}
				description={post?.seo?.metaDesc}
				language="en"
				openGraph={{
					type: "article",
					url: `${siteUrl}/blog/${post.slug}`,
					title: `${post?.seo?.opengraphTitle}`,
					description: `${post?.seo?.opengraphDescription}`,
					images: [
						{
							url: `${post?.seo?.twitterImage?.link}`,
							width: `${post?.seo?.twitterImage?.mediaDetails.width}`,
							height: `${post?.seo?.twitterImage?.mediaDetails.height}`,
							alt: `${post?.seo?.twitterImage?.altText}`,
						},
					],
				}}
			/>

			<section className=" bg-primary position-relative">
				<StaticImage
					src="../images/Hero-svg.svg"
					placeholder="blurred"
					quality={100}
					style={{ zIndex: 0 }}
					className="mb-4 position-absolute top-0 start-0  w-100 h-100"
				/>
				<Navigation bg="none" />
				<Container
					style={{ zIndex: 1 }}
					className="position-relative py-5 py-lg-7 "
				>
					<Row>
						<Col>
							<p className="text-med-grey ssp-semibold">
								Written by{" "}
								<span className="text-secondary">{post.author.node.name}</span>
							</p>
							<h1 className="pt-lg-5 pt-3 pb-7 roboto-bold text-white ">
								{post.title}
							</h1>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="position-relative">
				<Container>
					<Row className="justify-content-center">
						<Col xs={12} lg={10} xl={8}>
							<div
								className="position-relative"
								style={{
									borderRadius: "15px",
									overflow: "hidden",
									top: "-5rem",
								}}
							>
								<GatsbyImage
									className=" w-100 "
									image={post.blogFields.featuredImage?.gatsbyImage}
									alt={post.blogFields.featuredImage?.altText}
								/>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="position-relative ">
				<Container className="  pb-5 pb-lg-7">
					<Row className="justify-content-center">
						<Col lg={10} xl={8}>
							<div className="blog-content  ">{parse(post.content)}</div>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default BlogPostTemplate;

export const pageQuery = graphql`
	query PostById($id: String!) {
		post: wpPost(id: { eq: $id }) {
			seo {
				metaDesc
				title
				twitterImage {
					altText
					mediaDetails {
						height
						width
					}
					link
					gatsbyImage(
						width: 1920
						quality: 75
						formats: [WEBP]
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
				opengraphTitle
				opengraphDescription
			}
			id
			excerpt
			slug
			content
			author {
				node {
					name
				}
			}
			title

			blogFields {
				featuredImage {
					altText
					mediaDetails {
						height
						width
					}
					link
					gatsbyImage(
						width: 1920
						quality: 75
						formats: [WEBP]
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
